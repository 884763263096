#noty_layout__center {
	top: 40%;
}

.noty_theme__bootstrap-v3.noty_bar {
	box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.2);
	text-align: center;

	&.noty_type__alert {
		color: #895352;
		border-color: #b69b9a;
		background-color: #f3e2e2;
	}

	&.noty_type__info {
		color: #596d77;
		border-color: #68c6d9;
		background-color: #e2f1f8;
	}

	&.noty_type__warning {
		color: #745d34;
		border-color: #d8b369;
		background-color: #fcf7df;
	}

	&.noty_type__success {
		color: #355536;
		border-color: #99b483;
		background-color: #dff0d8;
	}

	.noty_body {
		max-height: 8em;
		padding: 1em;

		strong {
			display: block;
			font-size: 1.2em;
			font-weight: normal;
			line-height: normal;
			margin-bottom: 0.5em;
		}
	}

	.noty_buttons {
		padding: 0.5em;
		background: whitesmoke;
		border-top: 1px dashed gainsboro;

		.btn + .btn {
			margin-left: .5em;
		}
	}
}
