@import "~bootstrap/less/variables.less";

.code-editor {
	.modal-content {
		> section {
			display: flex;
		}
	}

	.fields-container {
		display: flex;
		flex-direction: column;
		width: 380px;
		background-color: #f2f2f2;
		border-right: 1px solid gainsboro;

		.tree {
			padding: 0.1em 0.4em;
			border: 0;

			&::-webkit-scrollbar {
				width: 10px;
				height: 10px;
			}

			&::-webkit-scrollbar-track {
				border: 1px solid #e0dfdf;
				border-right: 0;
				background-color: white;
			}

			&::-webkit-scrollbar-thumb {
				background: #c5c5c5;
			}

			&::-webkit-scrollbar-thumb:window-inactive {
				background: #c5c5c5;
			}

			&::-webkit-scrollbar-corner {
				background: #c5c5c5;
			}
		}

		.k-treeview {
			.k-item {
				padding-left: 14px;
			}

			.k-i-expand, .k-i-collapse {
				cursor: default;
			}

			.k-in {
				cursor: default;

				.item-text {
					padding: 0.1em 0.2em;
					cursor: pointer;
				}

				em {
					opacity: 0.5;
					font-size: 90%;
				}
			}
		}
	}

	.monaco-container {
		flex: 1;
	}

	header > .controls > .btn-close {
		display: none;
	}

	&.read-only header > .controls {
		> .btn-close {
			display: block;
		}

		> .btn-ok, > .btn-cancel {
			display: none;
		}
	}
}

#k-codeeditor {
	height: 0;
}
