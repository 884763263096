@import "~src/style/variables.less";

.tree.k-treeview {
	font-size: 12px;

	.k-selected {
		background: transparent;

		.item-navigation {
			background-color: rgba(3, 155, 229, 0.12);
		}
	}

	.k-icon {
		color: #a5a5a5;
	}

	.k-in {
		user-select: none;
		color: #656565;
		padding: 0;

		.status {
			position: relative;
			top: 3px;
		}

		.heading {
			opacity: 0.8;
			cursor: default;
			font-size: 90%;
		}

		.heading, a {
			padding: 0.3em 0.4em;
			display: inline-block;
		}

		a:hover {
			text-decoration: none;
		}

		span.fa {
			margin-right: 3px;
			opacity: 0.8;
		}

		a.item-edit > span {
			margin-left: 3px;
		}

		.item-match {
			font-weight: bold;
		}
	}

	.k-hover {
		color: @text-color;
		background-color: transparent;

		a {
			color: @link-color;
		}
	}

	.k-focus, .k-selected {
		box-shadow: none;
		-webkit-box-shadow: none;
	}

	span.status {
		margin: -6px 2px 0 -19px;
	}
}

.tree-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 0;

	header.limit-warning {
		display: none;
		padding: 0.3em;
		text-align: center;
		border-bottom: 1px solid #b5b5b5;
	}

	.controls {
		display: none;
	}

	.tree {
		overflow: auto;
		flex: 1;
	}

	.filter-text {
		margin-bottom: .6em;
	}

	.no-filter-results {
		opacity: 0.6;
	}

	.no-results {
		opacity: 0.5;
		display: none;
	}
}

.tree-container.with-limit-warning {
	header.limit-warning {
		display: block;
	}
}

.tree-container.with-no-content {
	justify-content: center;
	align-items: center;

	.controls, .tree {
		display: none;
	}

	.no-results {
		display: block;
	}
}

.tree-container.with-controls {
	.controls {
		display: block;
	}
}

.interactive {
	cursor: pointer;

	> i {
		opacity: 0.6;
	}

	&:hover {
		> i {
			opacity: 1;
		}
	}
}
